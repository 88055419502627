import "../../stylesheets/landing/application.scss"

document.addEventListener("turbolinks:load", () => {
  import("./advantage_splide").then(e => e.default())
  import("./hero_splide").then(e => e.default())
  import("./banner_splide").then(e => e.default())
  import("./menu").then(e => e.default())
  import("./denounced").then(e => e.default())

  import("./complaints_book_form").then(e => e.default())
  import("./contact_form").then(e => e.default())
  import("./denunciation_form").then(e => e.default())


  const text_areas = document.querySelectorAll("textarea")
  text_areas?.forEach(text_area => {
    text_area.addEventListener("input", () => {
      text_area.style.height = 'auto'
      text_area.style.height = text_area.scrollHeight + 'px'
    }, false)
  })

  const customerSupportItems = document.querySelectorAll(".customer_support-container")
  customerSupportItems?.forEach(item => {
    const img = item.querySelector('.normal');
    const imgColor = item.querySelector('.color')
    item.addEventListener("mouseover", () => {
      img.style.visibility = 'hidden'

      imgColor.style.visibility = 'visible'
      imgColor.style.transform = 'scale(1.1)'
      imgColor.style.zIndex = '1'
    })

    item.addEventListener("mouseleave", () => {
      imgColor.style.visibility = 'hidden'
      imgColor.style.transform = 'scale(1)'
      imgColor.style.zIndex = '-1'

      setTimeout( () => {
        img.style.visibility = 'visible'
      }, 200)
    })
  })

  const download_links = document.querySelectorAll(".download-files")
  download_links?.forEach(link => {
    if (link.dataset.rol === 'download') {
      const file = link.dataset.file
      link.addEventListener("click", () => {
        window.open(`/${file}`, '_blank');
      })
    }
  })

  const questions_card_items = document.querySelectorAll(".frequent-questions-card-item")
  questions_card_items?.forEach(item => {
    const arrow = item.querySelector('[role="tab"]')
    const content = item.querySelector('[role="contentinfo"]')
    arrow.addEventListener("click", () => {
      arrow.classList.toggle("active");
      content.classList.toggle("active");
    })
  })
  /*
  window.addEventListener("load",function(){
		setTimeout(function open(event){
			document.querySelector(".popup").style.display = "block";
		},
		1000
		)
	});

	document.querySelector("#close").addEventListener("click",function(){
		document.querySelector(".popup").style.display = "none";

	});*/
})
